<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap"></div>
        <div class="body_wrap">
          <div class="questionnaire_content">
            <el-card shadow="always">
              <div class="questionnaire_top_content">
                <span class="countQuestionnaire"
                  >{{ pageData.questionnaireList.length
                  }}{{ $t("questionnaire.span_countquestionnaire") }}</span
                >
                <el-alert
                  type="info"
                  :description="$t('questionnaire.alert_noticerevise_body')"
                  :closable="false"
                  show-icon
                  effect="dark"
                ></el-alert>
                <el-button
                  size="small"
                  type="primary"
                  @click="openQuestionnaireAdder"
                  >{{ $t("questionnaire.btn_addquestionnaire") }}</el-button
                >
                <el-dialog
                  :title="$t('questionnaire.title_add_questionnaire')"
                  v-model="pageData.questionnaireAdderVisible"
                  ref="questionnaireAdder"
                  :modal="true"
                  :close-on-click-modal="false"
                  :close-on-press-escape="false"
                  :center="true"
                >
                  <el-form
                    :model="formData.newQuestionnaire"
                    ref="newQuestionnaireForm"
                    class="newQuestionnaireForm"
                    size="mini"
                  >
                    <el-form-item
                      :label="$t('questionnaire.label_questionnaire_title')"
                    >
                      <el-input
                        v-model="formData.newQuestionnaire.title"
                        maxlength="60"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item>

                    <el-form-item
                      :label="$t('questionnaire.label_questionnaire_title_en')"
                    >
                      <el-input
                        v-model="formData.newQuestionnaire.title_en"
                        maxlength="60"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item>
                    <el-form-item>
                      <div class="new_quesitonnaire_transfer_content">
                        <div class="questionnaire_transfer_notice">
                          <el-row
                            ><el-tag type="primary">{{
                              $t("questionnaire.tag_sort_notice")
                            }}</el-tag></el-row
                          >
                        </div>
                        <el-transfer
                          v-model="pageData.selectedQuestionsIdListForAdd"
                          filterable
                          target-order="push"
                          :filter-placeholder="$t('text.placeholder')"
                          :titles="[
                            $t('questionnaire.title_allquestions'),
                            $t('questionnaire.title_selectedquestions'),
                          ]"
                          :data="pageData.questionListEnable"
                          :props="{
                            key: 'id',
                            label: 'content',
                          }"
                        >
                          <template #default="{ option }">
                            <div v-if="pageData.lang == 'ch'">
                              <el-col class="number_span">
                                [{{ option.number }}]
                              </el-col>
                              <el-col class="type_span"
                                >[{{ option.type_name }}]</el-col
                              >
                              <el-col class="target_span"
                                >[{{ option.target_name }}]</el-col
                              >
                              <el-col class="content_span">{{
                                option.content.substr(0, 30) + "..."
                              }}</el-col>
                            </div>
                            <div v-else-if="pageData.lang == 'en'">
                              <el-col class="number_span">
                                [{{ option.number }}]
                              </el-col>
                              <el-col class="type_span"
                                >[{{ option.type_name_en }}]</el-col
                              >
                              <el-col class="target_span"
                                >[{{ option.target_name_en }}]</el-col
                              >
                              <el-col class="content_span">{{
                                option.content_en.substr(0, 30) + "..."
                              }}</el-col>
                            </div>
                            <div v-else>
                              <el-col class="number_span">
                                [{{ option.number }}]
                              </el-col>
                              <el-col class="type_span"
                                >[{{ option.type_name_en }}]</el-col
                              >
                              <el-col class="target_span"
                                >[{{ option.target_name_en }}]</el-col
                              >
                              <el-col class="content_span">{{
                                option.content_en.substr(0, 30) + "..."
                              }}</el-col>
                            </div>
                          </template>
                        </el-transfer>
                      </div>
                    </el-form-item>
                  </el-form>
                  <template #footer>
                    <span>
                      <el-button
                        class="addQuestionnaireBtn"
                        type="success"
                        size="medium"
                        @click="doAddQuestionnaire(formData.newQuestionnaire)"
                        :loading="pageData.doAddQuestionnaireLoading"
                        >{{ $t("target.btn_save") }}</el-button
                      >
                    </span>
                  </template>
                </el-dialog>
              </div>
              <div class="questionnaire_body_content">
                <el-skeleton
                  animated
                  :loading="pageData.questionnaireTableSkeLoading"
                >
                  <template #template>
                    <el-skeleton-item variant="text" style="height: 3rem" />
                  </template>
                  <template #default>
                    <el-table
                      ref="questionnaireTable"
                      :header-cell-style="{
                        textAlign: 'left',
                        backgroundColor: '#f2f6fc',
                      }"
                      :cell-style="{ textAlign: 'left' }"
                      :data="pageData.questionnaireList"
                    >
                      <el-table-column
                        :label="$t('questionnaire.label_questionnaire_title')"
                      >
                        <template #default="scope">
                          <span v-if="pageData.lang == 'ch'">{{
                            scope.row.title
                          }}</span>

                          <span v-else-if="pageData.lang == 'en'">
                            {{ scope.row.title_en }}</span
                          >
                          <span v-else> {{ scope.row.title_en }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('question.label_isused')">
                        <template #default="scope">
                          <span
                            style="color: #f56c6c"
                            v-if="scope.row.isUsed > 0"
                            >{{ $t("text.inused") }}</span
                          >
                          <span style="color: #67c23a" v-else>{{
                            $t("text.notused")
                          }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('question.label_options')">
                        <template #default="scope">
                          <el-button
                            type="success"
                            size="mini"
                            @click="previewQustionnaire(scope.row)"
                            :disabled="true"
                            >{{ $t("questionnaire.btn_preview") }}</el-button
                          >

                          <el-button
                            type="primary"
                            size="mini"
                            @click="openQuestionnaireEditor(scope.row)"
                            v-loading.fullscreen.lock="
                              pageData.questionnaireEdtorLoading
                            "
                            >{{ $t("common.btn_revise") }}</el-button
                          >

                          <el-popconfirm
                            :title="
                              $t('questionnaire.notify_delete_questionnaire')
                            "
                            @confirm="deleteQuestionnaire(scope.row)"
                          >
                            <template #reference>
                              <el-button type="danger" size="mini">{{
                                $t("common.btn_delete")
                              }}</el-button>
                            </template>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-skeleton>
                <div class="dialog_content">
                  <div class="edit_dialog_content">
                    <el-dialog
                      :title="$t('questionnaire.title_edit_questionnaire')"
                      v-model="pageData.questionnaireEditerVisible"
                      ref="questionnaireEditer"
                      :modal="true"
                      :close-on-click-modal="false"
                      :close-on-press-escape="false"
                      :center="true"
                    >
                      <el-form
                        :model="formData.editQuestionnaire"
                        ref="editQuestionnaireForm"
                        class="editQuestionnaireForm"
                        size="mini"
                      >
                        <el-form-item
                          :label="$t('questionnaire.label_questionnaire_title')"
                        >
                          <el-input
                            v-model="formData.editQuestionnaire.title"
                            maxlength="60"
                            show-word-limit
                            size="medium"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>

                        <el-form-item
                          :label="
                            $t('questionnaire.label_questionnaire_title_en')
                          "
                        >
                          <el-input
                            v-model="formData.editQuestionnaire.title_en"
                            maxlength="60"
                            show-word-limit
                            size="medium"
                            :placeholder="$t('text.placeholder')"
                          ></el-input>
                        </el-form-item>
                        <el-form-item>
                          <div class="edit_questionnaire_transfer_content">
                            <div class="questionnaire_transfer_notice">
                              <el-row style="margin-bottom: 10px"
                                ><el-tag type="danger">{{
                                  $t(
                                    "questionnaire.tag_questions_deleted_notice"
                                  )
                                }}</el-tag></el-row
                              >
                              <el-row
                                ><el-tag type="primary">{{
                                  $t("questionnaire.tag_sort_notice")
                                }}</el-tag></el-row
                              >
                            </div>
                            <el-transfer
                              v-model="pageData.selectedQuestionsIdList"
                              filterable
                              target-order="push"
                              :filter-placeholder="$t('text.placeholder')"
                              :titles="[
                                $t('questionnaire.title_allquestions'),
                                $t('questionnaire.title_selectedquestions'),
                              ]"
                              :data="pageData.questionsListAll"
                              :props="{
                                key: 'id',
                                label: 'content',
                              }"
                            >
                              <template #default="{ option }">
                                <div v-if="pageData.lang == 'ch'">
                                  <el-col class="number_span">
                                    [{{ option.number }}]
                                  </el-col>
                                  <el-col class="type_span"
                                    >[{{ option.type_name }}]</el-col
                                  >
                                  <el-col class="target_span"
                                    >[{{ option.target_name }}]</el-col
                                  >
                                  <el-col class="content_span">{{
                                    option.content.substr(0, 30) + "..."
                                  }}</el-col>
                                </div>
                                <div v-else-if="pageData.lang == 'en'">
                                  <el-col class="number_span">
                                    [{{ option.number }}]
                                  </el-col>
                                  <el-col class="type_span"
                                    >[{{ option.type_name_en }}]</el-col
                                  >
                                  <el-col class="target_span"
                                    >[{{ option.target_name_en }}]</el-col
                                  >
                                  <el-col class="content_span">{{
                                    option.content_en.substr(0, 30) + "..."
                                  }}</el-col>
                                </div>
                                <div v-else>
                                  <el-col class="number_span">
                                    [{{ option.number }}]
                                  </el-col>
                                  <el-col class="type_span"
                                    >[{{ option.type_name_en }}]</el-col
                                  >
                                  <el-col class="target_span"
                                    >[{{ option.target_name_en }}]</el-col
                                  >
                                  <el-col class="content_span">{{
                                    option.content_en.substr(0, 30) + "..."
                                  }}</el-col>
                                </div>
                              </template>
                            </el-transfer>
                          </div>
                        </el-form-item>
                      </el-form>
                      <template #footer>
                        <span>
                          <el-button
                            class="editQuestionnaireBtn"
                            type="success"
                            size="medium"
                            @click="
                              doEditQuestionnaire(formData.editQuestionnaire)
                            "
                            :loading="pageData.doEditQuestionnaireLoading"
                            >{{ $t("target.btn_save") }}</el-button
                          >
                        </span>
                      </template>
                    </el-dialog>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        username: '',
        userinfo: {},
        lang: '',
        questionnaireList: [],
        currentQuestionnaire: {},
        questionnaireEditerVisible: false,
        questionnaireAdderVisible: false,
        oldQuestionnaire: '',
        doEditQuestionnaireLoading: false,
        doAddQuestionnaireLoading: false,
        questionnaireEdtorLoading: false,
        questionsListAll: [],
        questionListEnable: [],
        selectedQuestionsIdList: [],
        selectedQuestionsIdListForAdd: [],
        questionnaireTableSkeLoading: true
      },
      formData: {
        editQuestionnaire: {
          title: '',
          title_en: '',
          questionsList: []
        },
        newQuestionnaire: {
          title: '',
          title_en: '',
          questionsList: []
        }
      }
    };
  },
  mounted() {
    // document.title = this.$t('title.');
    this.pageData.username = sessionStorage.getItem('username');
    this.pageData.userinfo = JSON.parse(sessionStorage.getItem(this.pageData.username));
    this.pageData.lang = sessionStorage.getItem('lang');
    if (mountederjs.evaluateM(this)) {
      //get questions
      this.getQuestionsForQuestionnaire();
      this.getQuestionnaire();
      setTimeout(() => { this.show.wholeShow = true }, 1);
    } else {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    getQuestionnaire() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryQuestionnaireList',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.questionnaireList = response.data.data;
            this.pageData.questionnaireTableSkeLoading = false;

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('questionnaire.notify_questionnairenull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    getQuestionsForQuestionnaire() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryQuestionsWithoutIsdel',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.questionsListAll = response.data.data;

            //set disabled and type name
            for (let question of this.pageData.questionsListAll) {
              if (question.isdel == '0') {
                question.disabled = true;
              } else if (question.isdel == '1') {
                this.pageData.questionListEnable.push(question);
                question.disabled = false;
              } else {
                question.disabled = true;
              }

              if (question.type == 0) {
                question.type_name = '选择题';
                question.type_name_en = 'Multiple-choice questions'
              } else if (question.type == 1) {
                question.type_name = '主观题';
                question.type_name_en = 'Subjective questions'
              } else {
                return;
              }
            }

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('question.notify_questionnull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    getQuestionnaireDetails(questionnaire) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryQuestionnaireById',
        data: questionnaire
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.formData.editQuestionnaire = response.data.data;

            this.pageData.oldQuestionnaire = '';
            this.pageData.oldQuestionnaire = JSON.parse(JSON.stringify(this.formData.editQuestionnaire));

            for (let question of this.formData.editQuestionnaire.questionsList) {
              this.pageData.selectedQuestionsIdList.push(question.id);
            }

            this.pageData.questionnaireEdtorLoading = false;
            this.pageData.questionnaireEditerVisible = true;

            return;
          } else {
            //code = 700 and more
            this.pageData.questionnaireEdtorLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.questionnaireEdtorLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.questionnaireEdtorLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    openQuestionnaireAdder() {
      this.pageData.questionnaireAdderVisible = true;
    },
    doAddQuestionnaire(questionnaire) {
      this.doAddQuestionnaireLoading = true;

      //verify
      if (utilsjs.strIsEmpty(questionnaire.title) || utilsjs.strIsEmpty(questionnaire.title_en)) {
        this.$notify.error({
          title: this.$t("notify.title_err_type1"),
          message: this.$t('questionnaire.notify_questionnaire_incompletely')
        });
        this.pageData.doAddQuestionnaireLoading = false;
        return;
      }

      if (this.pageData.selectedQuestionsIdListForAdd.length <= 0) {
        this.$notify.error({
          title: this.$t("notify.title_err_type1"),
          message: this.$t('questionnaire.notify_quesioninquesitonnairenull')
        });
        this.pageData.doAddQuestionnaireLoading = false;
        return;
      }

      //set question list
      questionnaire.questionsList = [];
      questionnaire.questionConnectionList = [];
      for (let index in this.pageData.selectedQuestionsIdListForAdd) {
        let item = {};
        item.question_id = this.pageData.selectedQuestionsIdListForAdd[index];
        item.sort = index;
        item.creator = this.pageData.username;
        questionnaire.questionConnectionList.push(item);
      }

      //set values
      questionnaire.creator = this.pageData.username;
      questionnaire.reviser = this.pageData.username;

      this.postToAddQuestionnaire(questionnaire);
    },
    postToAddQuestionnaire(questionnaire) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/createQuestionnaire',
        data: questionnaire
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.doAddQuestionnaireLoading = false;
            this.pageData.questionnaireAdderVisible = false;
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            setTimeout(() => {
              location.reload();
            }, 1000);
            return;
          } else if (response.data.code == '290') {
            this.pageData.doAddQuestionnaireLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('questionnaire.notify_some_saved_err')
            });
            return;
          } else {
            //code = 700 and more
            this.pageData.doAddQuestionnaireLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.doAddQuestionnaireLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.doAddQuestionnaireLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    previewQustionnaire(questionnaire) {
      this.$notify.error({
        title: this.$t("notify.title_server"),
        message: this.$t('questionnaire.notify_preview_later'),
        duration: 10000
      });
      return;
    },
    openQuestionnaireEditor(questionnaire) {
      this.pageData.questionnaireEdtorLoading = true;
      this.pageData.selectedQuestionsIdList = [];
      this.formData.editQuestionnaire = {};

      this.getQuestionnaireDetails(questionnaire);
    },
    doEditQuestionnaire(questionnaire) {
      this.pageData.doEditQuestionnaireLoading = true;

      //verify
      if (utilsjs.strIsEmpty(questionnaire.title) || utilsjs.strIsEmpty(questionnaire.title_en) || utilsjs.strIsEmpty(questionnaire.id)) {
        this.$notify.error({
          title: this.$t("notify.title_err_type1"),
          message: this.$t('questionnaire.notify_questionnaire_incompletely')
        });
        this.pageData.doEditQuestionnaireLoading = false;
        return;
      }

      if (this.pageData.selectedQuestionsIdList.length <= 0) {
        this.$notify.error({
          title: this.$t("notify.title_err_type1"),
          message: this.$t('questionnaire.notify_quesioninquesitonnairenull')
        });
        this.pageData.doEditQuestionnaireLoading = false;
        return;
      }

      //set question list
      questionnaire.questionsList = [];
      questionnaire.questionConnectionList = [];
      for (let index in this.pageData.selectedQuestionsIdList) {
        let item = {};
        item.questionnaire_id = questionnaire.id;
        item.question_id = this.pageData.selectedQuestionsIdList[index];
        item.sort = index;
        item.creator = this.pageData.username;
        questionnaire.questionConnectionList.push(item);
      }

      this.postToReviseQuestionnaire(questionnaire);
    },
    postToReviseQuestionnaire(questionnaire) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/reviseQuestionnaire',
        data: questionnaire
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.pageData.doEditQuestionnaireLoading = false;
            this.pageData.questionnaireEditerVisible = false;
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            setTimeout(() => {
              location.reload();
            }, 1000);
            return;
          } else if (response.data.code == '290') {
            this.pageData.doEditQuestionnaireLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('questionnaire.notify_some_saved_err')
            });
            return;
          } else {
            //code = 700 and more
            this.pageData.doEditQuestionnaireLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.doEditQuestionnaireLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.doEditQuestionnaireLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    deleteQuestionnaire(questionnaire) {
      questionnaire.reviser = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/deleteQuestionnaireById',
        data: questionnaire
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_deleted"),
              type: 'success'
            });

            for (let index in this.pageData.questionnaireList) {
              if (questionnaire.id == this.pageData.questionnaireList[index].id) {
                this.pageData.questionnaireList.splice(index, 1);
              } else {
                continue;
              }
            }

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.questionnaire_content {
  margin-top: 10px;
  padding: 20px;
}
.countQuestionnaire {
  float: left;
  margin-left: 20px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #f56c6c;
}
.questionnaire_top_content .el-button {
  float: right;
  margin-top: 15px;
  margin-bottom: 20px;
}
.editQuestionnaireBtn,
.addQuestionnaireBtn {
  margin-top: -30px;
  float: right;
}
.edit_questionnaire_transfer_content {
  margin-top: 20px;
}
.new_quesitonnaire_transfer_content {
  margin-top: 20px;
}
.questionnaire_transfer_notice {
  margin-bottom: 20px;
}
.number_span {
  width: 20px;
  margin-right: 40px;
  color: #e6a23c;
}
.type_span {
  width: 20px;
  margin-right: 170px;
  color: #f56c6c;
}
.target_span {
  width: 20px;
  margin-right: 150px;
  color: #67c23a;
}
.content_span {
  color: #409eff;
}
</style>